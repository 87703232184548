/* global enquire */

var ResponsiveImages;

ResponsiveImages = function () {
	'use strict';

	var self = this;
	var screen = {
		lg: 1200,
		md: 992,
		sm: 768,
		xs: 480
	};

	enquire.register('screen and (min-width: ' + screen.lg + 'px)', {
		match: function () {
			self.setImages('lg');
		},
		setup: function () {
			self.setImages('lg');
		},
		deferSetup: true
	});

	enquire.register('screen and (min-width: ' + screen.md + 'px) and (max-width:' + (screen.lg - 1) + 'px)', {
		match: function () {
			self.setImages('md');
		},
		setup: function () {
			self.setImages('md');
		},
		deferSetup: true
	});

	enquire.register('screen and (min-width: ' + screen.sm + 'px)  and (max-width:' + (screen.md - 1) + 'px)', {
		match: function () {
			self.setImages('sm');
		},
		setup: function () {
			self.setImages('sm');
		},
		deferSetup: true
	});

	enquire.register('screen and (max-width:' + (screen.sm - 1) + 'px)', {
		match: function () {
			self.setImages('xs');
		},
		setup: function () {
			self.setImages('xs');
		},
		deferSetup: true
	});
};

ResponsiveImages.prototype.setImages = function (screenSize) {
	'use strict';

	var imgSrc;
	$('.content img').each(function () {
		imgSrc = $(this).attr('src');
		imgSrc = imgSrc.substring(imgSrc.lastIndexOf('/'));
		imgSrc = '/fileadmin/responsive/' + screenSize + imgSrc;
		$(this).attr('src', imgSrc);
	});
};