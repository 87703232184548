/**
 * The Frontpage module
 * @constructor
 */
var Frontpage = function () {
	'use strict';
	this.bindHoverEvents();
};

/**
 * Binds the hover events of the SVG image map
 */
Frontpage.prototype.bindHoverEvents = function () {
	'use strict';

	var navPies = $('.frontpage .pie');
	var paths = $('#nav-frontpage').find('path');

	paths.hover(function () {
		navPies.eq($(this).index()).addClass('hover');
	}, function () {
		navPies.eq($(this).index()).removeClass('hover');
	});


	paths.click(function () {
		if(navPies.eq($(this).index()).parents('a').attr('target') === '_blank') {
			var win = window.open(navPies.eq($(this).index()).parents('a').attr('href'), '_blank');
			win.focus();
		} else {
			window.location.href = navPies.eq($(this).index()).parents('a').attr('href');
		}


	});
};
