var League = {
	init: function() {
		'use strict';
		// this.getLastGame();
		this.getShortTable();
		this.getNextGame();
		this.getNextGames();
		this.getTable();
		this.getGameplan();
	},
	getLastGame: function() {
		'use strict';
		var lastGameParent = $('#last-game');
		this.ajaxAction(7586,lastGameParent);
	},
	getNextGame: function () {
		'use strict';
		var nextGameParent = $('#nextgame-header');
		this.ajaxAction(8586,nextGameParent);
	},
	getNextGames: function () {
		'use strict';
		var shortTableParent = $('#last-game');
		this.ajaxAction(6248,shortTableParent);
	},
	getShortTable: function () {
		'use strict';
		var shortTableParent = $('#shorttable-header');
		this.ajaxAction(9586,shortTableParent);
	},
	getTable: function() {
		'use strict';
		var tableParent = $('#hce-league-table');
		this.ajaxAction(6586,tableParent);
	},
	getGameplan: function() {
		'use strict';
		var gameplan = $('#gameplan');
		this.ajaxAction(5586,gameplan);
	},
	ajaxAction: function(type,parent) {
		if(window.location.hash) {
			var hash = window.location.hash;
		} else {
			var hash = false;
		}
		if(parent.length > 0) {
			$.ajax({
					   url: window.location.href,
					   data: {'type' : type}
				   }).success(function(msg) {
				parent.html(msg);
				parent.addClass('show');

				if(hash && $('#hce-league-table').length > 0) {
					var url = hash.split('#');
					if ($.browser.opera) {
						var target = 'html';
					} else {
						var target = 'html,body';
					}

					$(target).on("scroll mousedown wheel DOMMouseScroll mousewheel keyup touchmove", function () {
						$(target).stop();
					});
					if($('.onepager-element[data-id="'+url[1]+'"]').length > 0) {
						var topPos = $('.onepager-element[data-id="' + url[1] + '"]').offset().top - 100;

						$(target).animate({
											  scrollTop: topPos
										  }, 2000, function () {
							$(target).off("scroll mousedown wheel DOMMouseScroll mousewheel keyup touchmove");
						});
					}
				}
			});
		}
	}
};

$(document).ready(function(){
	League.init();
});

