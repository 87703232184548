/**
 * Created by bauermar on 24.07.15.
 */

/**
 * PAGER FOR EVENTS LIST VIEW PLUGIN
 */

var News;

News = function () {
	'use strict';

	var curClass = this;
	window.allowGetNews = true;

	$('a.pager-elem').first().addClass('active');


	sessionStorage.setItem('news-pager', 0);

	var body = $('body');

	body.on('click', 'a.pager-elem', function () {
		if(window.allowGetNews) {
			window.allowGetNews = false;
			var page = $(this).attr('data-value');
			if (!$(this).hasClass('active')) {
				curClass.getNews(page);
			}
			$('a.pager-elem.active').removeClass('active');
			$(this).addClass("active");
		}
	});

	body.on("click", ".news-pagination a.last", function () {
		if(window.allowGetNews) {
			window.allowGetNews = false;
			var countPages = parseInt(sessionStorage.getItem('news-count-pages')) - 1;

			curClass.getNews(countPages);
			$("a.pager-elem.active").removeClass("active");
			$('a.pager-elem[data-value="' + countPages + '"]').addClass("active");
		}
	});

	body.on("click", ".news-pagination a.first", function () {
		if(window.allowGetNews) {
			window.allowGetNews = false;
			curClass.getNews(0);
			$("a.pager-elem.active").removeClass("active");
			$('a.pager-elem[data-value="0"]').addClass("active");
		}
	});

	body.on("click", ".news-pagination a.next", function () {
		if(window.allowGetNews) {
			window.allowGetNews = false;
			var countPages = parseInt(sessionStorage.getItem('news-count-pages')) - 1;
			var currentPage = parseInt(sessionStorage.getItem('news-pager'));
			if (currentPage < countPages) {
				var nextPage = currentPage + 1;
				curClass.getNews(nextPage);
				$("a.pager-elem.active").removeClass("active");
				$('a.pager-elem[data-value="' + nextPage + '"]').addClass("active");
			} else {
				curClass.getNews(0);
				$("a.pager-elem.active").removeClass("active");
				$('a.pager-elem[data-value="0"]').addClass("active");
			}
		}
	});

	body.on("click", ".news-pagination a.prev", function () {
		if(window.allowGetNews) {
			window.allowGetNews = false;
			var countPages = parseInt(sessionStorage.getItem('news-count-pages')) - 1;
			var currentPage = parseInt(sessionStorage.getItem('news-pager'));
			if (currentPage > 0) {
				var prevPage = currentPage - 1;
				curClass.getNews(prevPage);
				$("a.pager-elem.active").removeClass("active");
				$('a.pager-elem[data-value="' + prevPage + '"]').addClass("active");
			} else {
				curClass.getNews(countPages);
				$("a.pager-elem.active").removeClass("active");
				$('a.pager-elem[data-value="' + countPages + '"]').addClass("active");
			}
		}
	});

	/* body.on("click",'.news-list-item .more', function () {
		var self = $(this);
		var parentObj = self.parents(".news-list-item");
		var InnerContentHeight = parentObj.find('.inner-content-wrapper').height() + 300;

		if (!self.parents(".news-list-item").hasClass('open')) {
			if ($('.news-list-item.open').length > 0) {
				TweenMax.to($('.news-list-item.open').find('.content-wrapper'), 0.6, {maxHeight: '255px'});
				$('.news-list-item.open').removeClass("open");

				TweenMax.to(parentObj.find('.content-wrapper'), 0.6, {maxHeight: InnerContentHeight}).eventCallback("onComplete", function(){
					var contentTop = self.parents(".news-list-item").offset().top;
					var headerHeight = $('#meta-header').height() + $('#main-header').height();
					if($(window).width() < 768) {
						headerHeight =  $('#main-header').height() + 12;
					}
					TweenMax.to(window, 1, {scrollTo: {y: contentTop - headerHeight}}).eventCallback("onComplete", function () {
						TweenMax.to('#preloader',1, { ease: Power0.easeNone, display:'none', opacity: 0} );
					});
				});
				self.parents(".news-list-item").addClass('open');
			} else {
				TweenMax.to(parentObj.find('.content-wrapper'), 0.6, {maxHeight: InnerContentHeight}).eventCallback("onComplete", function(){
					var contentTop = self.parents(".news-list-item").offset().top;
					var headerHeight = $('#meta-header').height() + $('#main-header').height();
					if($(window).width() < 768) {
						headerHeight =  $('#main-header').height() + 12;
					}
					TweenMax.to(window, 1, {scrollTo: {y: contentTop - headerHeight}}).eventCallback("onComplete", function () {
						TweenMax.to('#preloader',1, { ease: Power0.easeNone, display:'none', opacity: 0} );
					});
				});
				self.parents(".news-list-item").addClass('open');
			}
		} else {
			TweenMax.to(parentObj.find('.content-wrapper'), 0.6, {maxHeight: '255px'}).eventCallback("onComplete", function(){
				var contentTop = self.parents(".news-list-item").offset().top;
				var headerHeight = $('#meta-header').height() + $('#main-header').height();
				if($(window).width() < 768) {
					headerHeight =  $('#main-header').height() + 12;
				}
				TweenMax.to(window, 1, {scrollTo: {y: contentTop - headerHeight}}).eventCallback("onComplete", function () {
					
				});
			});
			self.parents(".news-list-item").removeClass('open')
		}

	}); */ 

	if(window.location.hash) {
		var hash = window.location.hash;
		var newsItem = hash.split('news-item-')[1];
		if(newsItem) {
			var newsId = newsItem.split('-page-')[0];
			var pageId = hash.split('-page-')[1];
			var parentPage = pageId - 1;
			this.getNews(parentPage);
			$("a.pager-elem.active").removeClass("active");
			$('a.pager-elem[data-value="' + parentPage + '"]').addClass("active");
			var timeouttwo = setTimeout(
				function () {
					$("#news-item-"+ newsId).find('.more').trigger('click');
					clearTimeout(timeouttwo);
				}, 1200);
		}
	}


};

News.prototype.getNews = function (page) {
	var self = this;
	console.log(page);
	var currentPage = parseInt(sessionStorage.getItem('news-pager'));
	if (currentPage != page) {
		var params = {
			tx_hcnews_pi1: {
				page: page
			}
		};

		var wrapper = $(".news-list .slider-wrapper:first");


		var timeoutone, timeouttwo;

		$.post('?type=777', params, function (result) {

			wrapper.append(result);

			var pagerSession = sessionStorage.getItem('news-pager');
			if (page < pagerSession) {
				wrapper.find(".slide.active").addClass("right");
				wrapper.find(".slide.ajaxContent").addClass("prev");

				timeoutone = setTimeout(
					function () {
						wrapper.find(".slide.ajaxContent").addClass("right");
					}, 600);
				timeouttwo = setTimeout(
					function () {
						clearTimeout(timeoutone);
						wrapper.find(".slide.active").remove();
						wrapper.find(".slide.ajaxContent").addClass("active").removeClass("right prev ajaxContent");
						window.allowGetNews = true;
						self.scrollToTopNews();
						clearTimeout(timeouttwo);
					}, 1200);
			} else {
				wrapper.find(".slide.active").addClass("left");
				wrapper.find(".slide.ajaxContent").addClass("next");

				timeoutone = setTimeout(
					function () {
						wrapper.find(".slide.ajaxContent").addClass("left");
					}, 600);
				timeouttwo = setTimeout(
					function () {
						clearTimeout(timeoutone);
						wrapper.find(".slide.active").remove();
						wrapper.find(".slide.ajaxContent").addClass("active").removeClass("left next ajaxContent");
						window.allowGetNews = true;
						self.scrollToTopNews();
						clearTimeout(timeouttwo);
					}, 1200);
			}
			sessionStorage.setItem('news-pager', page);

		});
	}
};

News.prototype.scrollToTopNews = function(){
	var newHash = $('.news-list').parents('section').data('id');
	window.location.hash = '#'+newHash;
	if(window.location.hash === '#'+newHash) {
		var contentTop = $('.news-list').parents('section').offset().top;
		var headerHeight = $('#meta-header').height() + $('#main-header').height();
		if($(window).width() < 768) {
			headerHeight =  $('#main-header').height() + 12;
		}
		TweenMax.to(window, 1, {scrollTo: {y: contentTop - headerHeight}}).eventCallback("onComplete", function () {

		});
	}
};