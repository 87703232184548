/**
 * Created by bauermar on 24.07.15.
 */

/**
 * PAGER FOR EVENTS LIST VIEW PLUGIN
 */

var Events;

Events = function () {
	'use strict';

	var curClass = this;


	$('a.pager-elem').first().addClass('active');


	sessionStorage.setItem('event-pager', 0);

	$('body').on('click', 'a.pager-elem', function () {
		var page = $(this).attr('data-value');
		if (!$(this).hasClass('active')) {
			curClass.getEvents(page);
		}
		$('a.pager-elem.active').removeClass('active');
		$(this).addClass("active");
	});

	$("body").on("click", ".event-pagination a.last", function () {
		var countPages = parseInt(sessionStorage.getItem('event-count-pages')) - 1;

		curClass.getEvents(countPages);
		$("a.pager-elem.active").removeClass("active");
		$('a.pager-elem[data-value="' + countPages + '"]').addClass("active");
	});

	$("body").on("click", ".event-pagination a.first", function () {
		curClass.getEvents(0);
		$("a.pager-elem.active").removeClass("active");
		$('a.pager-elem[data-value="0"]').addClass("active");
	});

	$("body").on("click", ".event-pagination a.next", function () {
		var countPages = parseInt(sessionStorage.getItem('event-count-pages')) - 1;
		var currentPage = parseInt(sessionStorage.getItem('event-pager'));
		if (currentPage < countPages) {
			var nextPage = currentPage + 1;
			curClass.getEvents(nextPage);
			$("a.pager-elem.active").removeClass("active");
			$('a.pager-elem[data-value="' + nextPage + '"]').addClass("active");
		} else {
			curClass.getEvents(0);
			$("a.pager-elem.active").removeClass("active");
			$('a.pager-elem[data-value="0"]').addClass("active");
		}
	});

	$("body").on("click", ".event-pagination a.prev", function () {
		var countPages = parseInt(sessionStorage.getItem('event-count-pages')) - 1;
		var currentPage = parseInt(sessionStorage.getItem('event-pager'));
		if (currentPage > 0) {
			var prevPage = currentPage - 1;
			curClass.getEvents(prevPage);
			$("a.pager-elem.active").removeClass("active");
			$('a.pager-elem[data-value="' + prevPage + '"]').addClass("active");
		} else {
			curClass.getEvents(countPages);
			$("a.pager-elem.active").removeClass("active");
			$('a.pager-elem[data-value="' + countPages + '"]').addClass("active");
		}
	});


	$('body').on('click', '.event-list-item', function (e) {
		var target = $(e.target), article;

		var videoElement = $(this).find(".video-element iframe");
		if (!$(this).hasClass('open') && !target.is('.close-details')) {
			$(this).parent().addClass('open');
			var self = $(this);
			var timeoutdetails = setTimeout(
				function (e) {
					self.addClass("open");
					clearTimeout(timeoutdetails);
					var Curheight = self.height();
					self.parent().css({height: Curheight + 'px'});
				}, 1000);
			var timeoutdetailstwo = setTimeout(
				function (e) {
					if (videoElement.length > 0) {
						var dataSrc = videoElement.attr('data-src');
						videoElement.attr('src', dataSrc);
					}
					clearTimeout(timeoutdetailstwo);
				}, 1000);
		}
	});

	$('body').on('click', '.close-details', function (e) {
		e.preventDefault();
		var videoElement = $(this).parents('.event-list-item').find(".video-element iframe");
		$(this).parents('.event-list-item').removeClass("open");
		$(this).parents('.container').removeClass('open').css({
			height: 'auto'
		});
		if (videoElement.length > 0) {
			videoElement.attr('src', '');
		}
	});

};

Events.prototype.getEvents = function (page) {
	var currentPage = parseInt(sessionStorage.getItem('event-pager'));
	if (currentPage != page) {
		var params = {
			tx_ercasemployer_pi1: {
				action: "ajaxshow",
				controller: "Employer",
				vendor: 'ERCAS',
				page: page
			}
		};
		$.post('?type=666', params, function (result) {
			$("#slider-wrapper").append(result);
			var pagerSession = sessionStorage.getItem('event-pager');
			if (page < pagerSession) {
				$(".slide.active").addClass("right");
				$(".slide.ajaxContent").addClass("prev");

				var timeoutone;
				var timeouttwo;
				timeoutone = setTimeout(
					function (e) {
						$(".slide.ajaxContent").addClass("right");

					}, 600);
				timeouttwo = setTimeout(
					function (e) {
						clearTimeout(timeoutone);
						$(".slide.active").remove();
						$(".slide.ajaxContent").addClass("active").removeClass("right prev ajaxContent");
						clearTimeout(timeouttwo);
					}, 1200);
			} else {
				$(".slide.active").addClass("left");
				$(".slide.ajaxContent").addClass("next");

				var timeoutone;
				var timeouttwo;
				timeoutone = setTimeout(
					function (e) {
						$(".slide.ajaxContent").addClass("left");

					}, 600);
				timeouttwo = setTimeout(
					function (e) {
						clearTimeout(timeoutone);
						$(".slide.active").remove();
						$(".slide.ajaxContent").addClass("active").removeClass("left next ajaxContent");
						clearTimeout(timeouttwo);
					}, 1200);
			}
			sessionStorage.setItem('event-pager', page);

		});
	}
};