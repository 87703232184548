'use strict';

Namespace('hcErlangen').
Class('Players', {

    _el: null,
    _viewConfig: {
        memberType: null,
        position: null,
        member: null
    },
    _standingsEl: null,
    _scrollConfig: {mouseWheel: true, scrollbars: 'custom', interactiveScrollbars: true, shrinkScrollbars: 'scale'},
    _playerDetail: {
        el: null,
        memberTypes: null,
        positions: null,
        members: {
            el: null,
            scroller: null
        },
        playerCards: null
    },
    _isPhone: false,

    construct: function () {


        if($(window).width() < 768) {
            this._isPhone = true;
        }


        // find all elements
        this._el = $('.tx-ercas-players');
        if (this._el.length == 0) return;
        this._standingsEl = $(this._el.get(0));
        this._initPlayerDetail(this._el.get(0));

        // events
        this._registerEvents();

        // set entry props
        this._initialActivate();
    },
    
    _initialActivate: function () {
        this._myPhonePlayers = this._playerDetail.el.find("select#select-member").find('option');
        // set memberType
        if(this._isPhone) {
            var _memberEl = this._playerDetail.el.find('#select-membertype option:first');
            this._activateMemberType(_memberEl.data('view-member-type'), _memberEl);

        } else {
            var _memberEl = this._playerDetail.memberTypes.find('a:visible[data-view-member-type]:first');
            this._activateMemberType(_memberEl.data('viewMemberType'), _memberEl);
        }


        // set Position

        if(this._isPhone) {
            var _position = this._playerDetail.el.find('select#select-position option[data-view-position]:first');
            this._activatePosition(_position.data('viewPosition'), _position);
        } else {
            var _position = this._playerDetail.positions.find('a:visible[data-view-position]:first');
            this._activatePosition(_position.data('viewPosition'), _position);
        }



        // set Player

        if(this._isPhone) {
            var _player = this._playerDetail.el.find('select#select-member option');
            var playerId;
            _player.each(function(){
                if ($(this).css('display') != 'none') {
                    playerId = $(this);
                    return false;
                }
            });
            if(playerId)
            this._activatePlayer(playerId.data('view-member'));
        } else {
            var _player = this._playerDetail.members.el.find('a:visible[data-view-member]:first');
            this._activatePlayer(_player.data('viewMember'));
        }





    },
    _afterSelectChange: function (evt) {
        var parElem = $(evt.target);
        var target = $(evt.target).find(":selected");
        if(target.data("view-member")) {
            this._activatePlayer(parseInt(target.data("view-member")), true);
        } else if(target.data('view-position')) {
            this._activatePosition(target.data('view-position'), target);
        } else {
            this._activateMemberType(target.data('view-member-type'), target);
        }
    },
    _registerEvents: function () {

        if(this._isPhone) {
            this._playerDetail.el.find('select.nav').on('change', $.proxy(this._afterSelectChange,this));
        } else {
            // prevent all links from targeting
            this._el.find('a').on('click', $.proxy(this._noLinkAction, this));

            // players standings
            this._standingsEl.find('a[data-view-member]').on('click', $.proxy(this._onPlayerStandingClick, this));

            // memberTypes
            this._playerDetail.memberTypes.find('a').on('click', $.proxy(this._onMemberTypeClick, this));
            // positions
            this._playerDetail.positions.find('a').on('click', $.proxy(this._onPositionListClick, this));
            // players list
            this._playerDetail.members.el.find('a').on('click', $.proxy(this._onPlayerListClick, this));
        }
    },

    _onPositionListClick: function (evt) {
        var target = $(evt.currentTarget);

        if (this._viewConfig.position == target.data('viewPosition')) return; // same player activated again

        this._activatePosition(target.data('viewPosition'), target);
    },

    _onPlayerListClick: function (evt) {
        var target = $(evt.currentTarget);

        if (this._viewConfig.member == target.data('viewMember')) return; // same player activated again

        this._activatePlayer(parseInt(target.data('viewMember')), true); // noScroll
    },

    _onPlayerStandingClick: function (evt) {
        var target = $(evt.currentTarget);

        if (this._viewConfig.member == target.data('viewMember')) return; // same player activated again

        this._activatePlayer(parseInt(target.data('viewMember')));
    },

    _onMemberTypeClick: function (evt) {
        var target = $(evt.currentTarget);

        if (this._viewConfig.memberType == target.data('viewMemberType')) return; // same el clicked again

        this._activateMemberType(target.data('viewMemberType'), target);
    },

    _activateMemberType: function (id, target) {
        var updatePlayer = false;
        // target not set?
        if (!target) target = this._playerDetail.memberTypes.find('[data-view-member-type=' + id + ']:first');

        if(this._viewConfig.memberType != id) {
            updatePlayer = true
        }

        // new member type
        this._viewConfig.memberType = id;

        // set classes
        this._playerDetail.memberTypes.find('.active').removeClass('active');
        target.addClass('active');

        switch (this._viewConfig.memberType) {
            case 1:
                // Spieler
                if(this._isPhone) {
                    this._playerDetail.el.find("#select-position").removeClass("disable");
                } else {
                    this._playerDetail.positions.css("opacity", 1);
                }
                break;
            case 2:
                // Stab
                if(this._isPhone) {
                    this._playerDetail.el.find("#select-position").addClass("disable");
                } else {
                    this._playerDetail.positions.css("opacity", 0);
                }

                break;
        }

        if(this._isPhone && updatePlayer) {
            this._filterPlayers();
        } else {
            this._filterPlayers();
        }
        if(this._isPhone) {
            var _player = this._playerDetail.el.find("select#select-member").find('option').first();
            this._activatePlayer(_player.data('viewMember'), false, true)
        }
    },

    _activatePosition: function (id, target, noPlayerActivate) {
        var updatePlayer = false;
        // id undefined?
        if (!id) return;

        // target undefined?
        if (!target) target = this._playerDetail.positions.find('[data-view-position=' + id + ']');

        if(this._viewConfig.position != id) {
            updatePlayer = true
        }

        this._viewConfig.position = id;

        this._playerDetail.positions.find('a.active').removeClass('active');
        target.addClass('active');

        // filter them
        if(this._isPhone && updatePlayer) {
            this._filterPlayers();
        } else {
            this._filterPlayers();
        }
        // activate the 1rst in list
        if (!noPlayerActivate) {
            if(this._isPhone) {
                var _player = this._playerDetail.el.find("select#select-member").find('option').first();
            } else {
                var _player = this._playerDetail.members.el.find('a:visible[data-view-member]:first');

            }
            this._activatePlayer(_player.data('viewMember'), false, true)
        }
    },

    _myPhonePlayers: [],

    _filterPlayers: function () {
        if(!this._isPhone) {
            var _players = this._playerDetail.members.el.find('a')
                .removeClass('.active')
                .hide()
                .filter('[data-member-type=' + this._viewConfig.memberType + ']');

            if (this._viewConfig.memberType == 1) _players = _players.filter('[data-position=' + this._viewConfig.position + ']');

            _players.show();


            // refresh scrollbar
            this._playerDetail.members.scroller.refresh();
        } else {
            var self = this;
            this._playerDetail.el.find("select#select-member").find('option').remove();
            $.each(this._myPhonePlayers,function(key,val){
                var addItem = false;
                if (self._viewConfig.memberType == $(val).data("member-type")) {
                        addItem = true;
                    if (self._viewConfig.memberType == 1) {
                        if (self._viewConfig.position == $(val).data("position")) {
                            addItem = true;
                        } else {
                            addItem = false;
                        }
                    }
                }
                if(addItem) {
                    self._playerDetail.el.find("select#select-member").append($(val));
                }
            });
            self._playerDetail.el.find("select#select-member").find('option').first().prop('selected');
        }
    },



    _activatePlayer: function (id, noScroll) {
        // activate
        this._viewConfig.member = id;
        this._playerDetail.members.el.find('a.active').removeClass('active');
        var _playerEl = this._playerDetail.members.el.find('a[data-view-member=' + id + ']').addClass('active');


        if(!this._isPhone) {
            this._activatePosition(_playerEl.data('position'), null, true);
            this._playerDetail.members.scroller.refresh();
            if (!noScroll) this._playerDetail.members.scroller.scrollToElement(_playerEl.get(0));
        }
        this._activatePlayerCard(id);
    },

    _activatePlayerCard: function (id) {
        // hide other player cards
        this._playerDetail.playerCards.find('.member.active').removeClass('active');

        var _card = this._playerDetail.playerCards.find('[data-member=' + id + ']').addClass('active');

        var _lineup = _card.find('.lineup'),
            _pos = _lineup.data('active');
        _lineup.find('.player-pos.' + _pos).addClass('active');

    },

    _noLinkAction: function (evt) {
        evt.preventDefault();
        evt.stopPropagation();
    },

    _initPlayerDetail: function (el) {
        this._playerDetail.el = $(el);

        this._playerDetail = $.extend(this._playerDetail, {
            memberTypes: this._playerDetail.el.find('.member-types:first'),
            positions: this._playerDetail.el.find('.positions:first'),
            members: {el: this._playerDetail.el.find('.members:first')},
            playerCards: this._playerDetail.el.find('.member-container:first')
        });

        this._initPlayerOverflowScrolling();
    },

    _initPlayerOverflowScrolling: function () {
        // member scroller
        if(!this._isPhone) {
            this._playerDetail.members.scroller = new IScroll(this._playerDetail.members.el.get(0), this._scrollConfig);
        }
    }
});
