var GoogleMaps;

GoogleMaps = function (options) {
	'use strict';
	options = options || {};
	options.jsonScriptClass = options.jsonScriptClass || 'google-maps';
	options.title = options.title || 'Mehr Informationen';

	$('script.' + options.jsonScriptClass).each(function () {
		var args = $.parseJSON($(this).html());
		var bounds = new google.maps.LatLngBounds();
		var mapElem = $('<div>');
		mapElem
			.css({width: '100%', height: args.height || 400})
			.addClass('google-map')
			.insertAfter($(this));

		var markerCount = args.length;

		var mapOptions = {
			center: new google.maps.LatLng(args[1].container.lat, args[1].container.lng),
			zoom: parseInt(args[1].container.zoom),
			disableDefaultUI: true,
			panControl: true,
			zoomControl: true,
			zoomControlOptions: {
				style: google.maps.ZoomControlStyle.SMALL
			}
		};
		var map = new google.maps.Map(mapElem.get(0), mapOptions);
		$.each(args, function (key, data) {
			var latLng = new google.maps.LatLng(data.container.lat, data.container.lng);
			// Creating a marker and putting it on the map
			var marker = new google.maps.Marker({
				position: latLng,
				map: map,
				title: data.container.title || options.title
			});

			bounds.extend(marker.position);

			if (data.container.infowindow) {
				var infoWindow = new google.maps.InfoWindow({
					content: '<span class="infowindow-title">' + data.container.title + '</span>' + data.container.infowindow
				});
			}
			google.maps.event.addListener(marker, 'click', function () {
				infoWindow.open(map, marker);
			});

			marker.setMap(map);
		});

		// map.fitBounds(bounds);


	});
};
