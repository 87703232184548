/**
 * Created by bauermar on 08.12.15.
 */

var Navigation;

Navigation = function () {
	'use strict';
	var self = this;
	window.changeSlide = 0;
	$('.subpage-selector').on('click',function(e){
		e.preventDefault();
		if(window.changeSlide == 0) {
			window.changeSlide = 1;
			// DEFINE VARIABLES
			var selector = $(this);
			var pageUid = selector.data('page-uid');

			// CALL CHANGE PAGE FUNCTION
			self.changePage(pageUid, function(){
				window.changeSlide = 0;
			});

		}
	});
	$('.navigation-page a').on('click', function(e){
		window.autoScrollFunction = true;
		// if("onhashchange" in window) {
		// 	$(this).parents('ul').find('a.active.current').removeClass('active current');
		// 	$(this).addClass('current active');
		// }

		if(this.href.split('#')[0] == window.location.href.split('#')[0]) {
			e.preventDefault();

			var curATag = $(this);
			var urlpath = $(this).attr('href').split('#')[1];

			if(urlpath) {

				var headerHeight = $('#meta-header').height() + $('#main-header').height();
				if($(window).width() < 768) {
					headerHeight =  $('#main-header').height() + 12;
				}

				TweenMax.to(window, 1, {scrollTo: {y: $('section.onepager-element[data-id="' + urlpath + '"]').offset().top - headerHeight}}).eventCallback("onComplete", function () {
					$(".navigation-page a.active.current").removeClass("active current");
					curATag.addClass("active current");
					window.autoScrollFunction = false;
					if($('html').hasClass('navigation-open')) {
						self.toggleNavigation();
					}
				});
			} else {
				TweenMax.to(window, 1, {scrollTo: {y: 0}}).eventCallback("onComplete", function () {
					$(".navigation-page a.active.current").removeClass("active current");
					curATag.addClass("active current");
					window.autoScrollFunction = false;
					if($('html').hasClass('navigation-open')) {
						self.toggleNavigation();
					}
				});
			}
		}
	});

	$('.return-page').on('click',function(e){
		e.preventDefault();

		// DEFINE VARIABLES
		var selector = $(this);
		var parentSelector = selector.parent();
		var parentPage = selector.data('return-pid');

		// SWITCH TO PARENT PAGE
		parentSelector.removeClass('show');
		$('.navigation-page[data-parent-pid='+parentPage+']').removeClass('right');

		$('#main-navigation').height($('.navigation-page[data-parent-pid='+parentPage+']').height());

	});

	$('#overlay-background').on('click touchend', function(e){
		e.preventDefault();
		if($('html').hasClass('navigation-open')) {
			self.toggleNavigation();
		}
	});

	$('button[data-toggle=ercas-navigation]').on('click touchend',function(e){
		e.preventDefault();
		self.toggleNavigation();
	});

	var curOpenSlider = $('.navigation-page[class="navigation-page show"]');
	$('#main-navigation').height(curOpenSlider.outerHeight());


	var Scrollable = '.scrollable';
	$(document).on('touchmove', function(e) {
		if($('html').hasClass('navigation-open')) {
			e.preventDefault();
		}
	});

	$('body').on('touchstart', Scrollable, function(e) {
		if (e.currentTarget.scrollTop === 0) {
			e.currentTarget.scrollTop = 1;
		} else if (e.currentTarget.scrollHeight === e.currentTarget.scrollTop + e.currentTarget.offsetHeight) {
			e.currentTarget.scrollTop -= 1;
		}
	});

	$('body').on('touchmove', Scrollable, function(e) {
		// Only block default if internal div contents are large enough to scroll
		// Warning: scrollHeight support is not universal. (http://stackoverflow.com/a/15033226/40352)
		if($('html').hasClass('navigation-open')) {
			if ($(this)[0].scrollHeight > $(this).innerHeight()) {
				e.stopPropagation();
			}
		}
	});

 //Stops preventDefault from being called on document if it sees a scrollable div
	//$('body').on('touchmove', Scrollable, function(e) {
	//	e.stopPropagation();
	//});

	// $(window).on('hashchange', function(e){
	// 	e.preventDefault();
	// 	console.log(window.location.hash);
	// 	if(window.location.hash != '') {
	// 		self.searchElement(window.location.hash);
	// 	} else {
	// 	}
	// });
	$(window).load(function() {
		if (window.location.hash === '#!window-top') {
			$(".navigation-page.show li:first-child a").addClass("active current");
			self.searchElement(window.location.hash);
		} else {
			self.searchElement(window.location.hash);
		}
	});
	// var lastScrollTop = 0;
	// $(window).scroll(function(){
	// 	var st = $(this).scrollTop();
	// 	if (st > lastScrollTop){
	//
	// 	} else {
	// 		if($(window).scrollTop() < $('#slideshow').offset().top && !window.autoScrollFunction) {
	// 			window.location.hash = '#!window-top';
	// 			$(".navigation-page a.active.current").removeClass("active current");
	// 			$(".navigation-page.show li:first-child a").addClass("active current");
	// 		}
	// 	}
	// 	lastScrollTop = st;
	// });

	var matrix = [];

	$('section.onepager-element').each(function (index) {
		var self = $(this);
		// var selfId = self.attr('data-id');
		// var selfTop = self.offset().top;
		matrix[parseInt(index)] = $(this);
	});
	var headerHeight = $('#meta-header').height() + $('#main-header').height();
	if($(window).width() < 768) {
		headerHeight =  $('#main-header').height() + 12;
	}
	window.addEventListener('scroll', function(e) {
		var distanceY = window.pageYOffset || document.documentElement.scrollTop,
				shrinkOn = $(window).height();

		$.each(matrix ,function(key, value){
			var objSection = value;
			var objTop = objSection.offset().top - headerHeight;

			if(matrix[key + 1]) {
				if (objTop <= distanceY && distanceY < matrix[key + 1].offset().top - headerHeight) {
					window.location.hash = objSection.attr('data-id');
					var url = window.location.href;
					url = url.replace(/^.*\/\/[^\/]+/, '');
					$(".navigation-page a.active.current").removeClass("active current");
					$(".navigation-page.show li a[href='"+url+"']").addClass("active current");
				}
			} else if(objTop <= distanceY) {
				window.location.hash = objSection.attr('data-id');
				// console.log(objSection);
			} else if(matrix[0].offset().top - headerHeight > distanceY) {
				window.location.hash = '!';
				$(".navigation-page a.active.current").removeClass("active current");
				$(".navigation-page.show li:first-child a").addClass("active current");
			}
			// console.log(key,value);
		});

		if (distanceY > shrinkOn) {

		} else {

		}
	});
	// $('body').on('touchmove', 'body', function (e) {
	// 	if(!$('html').hasClass('navigation-open'))
	// 		e.stopPropagation();
	// });
	window.addEventListener('scroll touchmove', function(e) {
		if(!$('html').hasClass('navigation-open'))
			e.stopPropagation();
	});
};

Navigation.prototype.searchElement =  function(hash){
	'use strict';
	var self = this;
	window.autoScrollFunction = true;
	if (hash === '#!window-top') {
		TweenMax.to(window, 1, {scrollTo: {y: 0}}).eventCallback("onComplete", function () {
			$(".navigation-page a.active.current").removeClass("active current");
			$(".navigation-page.show li:first-child a").addClass("active current");
			window.autoScrollFunction = false;
		});
	} else {
		var id = hash.split('#');
		if (id.length > 1) {
			var lowerUrl = id[1].toLowerCase();
			var resone = lowerUrl.replace(' ', '_');
			var restwo = resone.replace('ä', 'ae');
			var resthree = restwo.replace('ö', 'oe');
			var resfour = resthree.replace('ü', 'ue');
			var result = resfour.replace('ß', 'ss');

			if ($('section[data-id="' + result + '"]').length > 0) {
				var contentTop = $('section[data-id="' + result + '"]').offset().top;

				var headerHeight = $('#meta-header').height() + $('#main-header').height();
				if($(window).width() < 768) {
					headerHeight =  $('#main-header').height() + 12;
				}

				TweenMax.to(window, 1, {scrollTo: {y: contentTop - headerHeight}}).eventCallback("onComplete", function () {
					$(".navigation-page a.active.current").removeClass("active current");
					$(".navigation-page a").filter(function () {
						return this.hash == hash;
					}).addClass("active current");
					window.autoScrollFunction = false;
					if($('html').hasClass('navigation-open')) {
						self.toggleNavigation();
					}
				});
			}
		}
	}
};

Navigation.prototype.toggleNavigation = function(){
	'use strict';
	var self = this;
	var nav = $('html');
	var bodyElement = $(document.body);
	if (nav.hasClass('navigation-open')) {
		nav.removeClass('navigation-open');
		TweenMax.to($('#navigations '), 0.6, {width: 0});
		TweenMax.to($('#overlay-background'), 1,  {opacity: 0}).eventCallback("onComplete", function () {
		});

		$('html,body').attr('style', '');
		//this.changeMenuButton(0);
		// bodyElement.scrollTop(window.scrollPos);
		// window.scrollPos = 0;
	} else {
		window.scrollPos = document.body.scrollTop;
		var NavInnerWidth = $('#navigations ').find('#main-navigation').width();
		TweenMax.to($('#overlay-background'),1, { ease: Power0.easeNone, display:'block', opacity: 1} );
		TweenMax.to($('#navigations '), 0.6, {width: NavInnerWidth});
			nav.addClass('navigation-open');

			$('html').height($(window).height()).css({overflow: 'hidden'});
			var newScrollPos = 0;
			if (window.scrollPos > $(window).height()) {
				newScrollPos = window.scrollPos  ;
			} else {
				newScrollPos = window.scrollPos;
			}
			document.documentElement.scrollTop = document.body.scrollTop = newScrollPos;
			bodyElement.height($(window).height());
	}
};

Navigation.prototype.changePage = function (pageUid, callback) {
	'use strict';
	// DEFINE VARIABLES
	var sliderToOpen = $('.navigation-page[data-parent-pid="'+pageUid+'"]');
	var curOpenSlider = $('.navigation-page[class="navigation-page show"]');

	if(!sliderToOpen.hasClass('show')) {
		// CALL FUNCTIONS
		curOpenSlider.addClass('right');
		sliderToOpen.addClass('show');
		$('#main-navigation').height(sliderToOpen.height());

		var naviDiv = document.getElementById('scroll-navigation');
		naviDiv.scrollTop = 0;
	}
	callback();
};

Navigation.prototype.changeMenuButton = function (state) {
	'use strict';
	var speed = 300;
	var o = Snap('button[data-toggle=ercas-navigation] object#menu-icon');
	var c = $('button[data-toggle=ercas-navigation] object#close-icon');
	var lineOne = o.select('#line-1');
	var lineTwo = o.select('#line-2');
	var lineThree = o.select('#line-3');
	var bbox = lineThree.getBBox();
	if(state == 1) {
		lineOne.animate({ transform:'t0,14' , opacity:'0'}, speed);
		lineThree.animate({ opacity:'0' }, speed);
		lineTwo.animate({ transform:'t0,-14', opacity:'0' }, speed);
		bbox = lineThree.getBBox();
		c.animate({ opacity:'1' }, speed);
		setTimeout(function(){
			//lineOne.animate({ transform:'r45,'+ bbox.cx + ',' + bbox.cy+',t14,14', width: 12, height:2 }, speed);
			//lineThree.animate({ opacity:'0' }, 600);
			//lineOne.animate({ opacity:'0' }, speed);
			//lineThree.animate({ opacity:'0' }, speed);
			//lineTwo.animate({ opacity:'0' }, speed);

			//lineTwo.animate({ transform:'r-45,'+ bbox.cx + ',' + bbox.cy+',t14,-14', width: 12, height:2 }, speed);
		},speed  );

	} else {
		//lineOne.animate({ transform:'r0,'+ bbox.cx + ',' + bbox.cy+',t0,14', width: 40, height:4 }, speed);
		//lineThree.animate({ opacity:'0' }, 600);
		//lineTwo.animate({ transform:'r0,'+ bbox.cx + ',' + bbox.cy+',t0,-14', width: 40, height:4 }, speed);
		c.animate({ opacity:'0' }, 100);
		setTimeout(function(){
			lineOne.animate({ transform:'' }, speed);
			lineOne.animate({opacity:'1' }, speed);
			lineTwo.animate({ opacity:'1' }, speed);
			lineThree.animate({ opacity:'1' }, speed);
			lineTwo.animate({ transform:'' }, speed);
		},speed);
	}
};

// jQuery must be loaded!
if (typeof jQuery !== 'undefined') {
	$(document).ready(function () {
		'use strict';

		// INIT NAVIGATION
		new Navigation();
	});
} else {
	console.warn('jQuery is not available. Navigation.js can\'t be loaded.');
}

