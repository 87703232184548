function loadScript(src, callback) {

	var script = document.createElement("script");
	script.type = "text/javascript";
	script.async = 'async';
	if (callback)script.onload = callback;
	document.getElementsByTagName("head")[0].appendChild(script);
	script.src = src;
}

var Customer;

/**
 * INIT FUNCTION FOR ALL CUSTOMER FUNCTIONS
 * @constructor
 */

Customer = function () {
	'use strict';

	var self = this;

	this.resizeDelay = 100; // ms
	this.resizeTimout = null;

	// This construct reduces the calls of the onResize() handler. onResize() is not triggered
	// as long as further resize events are fired in an time interval of resizeDelay ms.
	$(window).resize(function () {
		clearTimeout(self.resizeTimeout);
		self.resizeTimout = setTimeout(self.onResize, self.resizeDelay);
	});

	// $('html').addClass('is-promo');

	// var pbTimer = setTimeout(function() {
	// 	$('#promotional-banner').fadeOut(function () {
	// 		$('html').removeClass('is-promo');
	// 		$(this).remove();
	// 	});
	// }, 10000);

	// $('#close-pb').on('click', function(e){
	// 	e.preventDefault();
	// 	clearTimeout(pbTimer);
	// 	$('html').removeClass('is-promo');
	// 	$('#promotional-banner').remove();
	// });


	new Frontpage();
	//new Players();
	this.Players = new hcErlangen.Players();

	// requestAnimationFrame Polyfill
	(function () {
		var lastTime = 0;
		var vendors = ['ms', 'moz', 'webkit', 'o'];
		for (var x = 0; x < vendors.length && !window.requestAnimationFrame; ++x) {
			window.requestAnimationFrame = window[vendors[x] + 'RequestAnimationFrame'];
			window.cancelAnimationFrame  = window[vendors[x] + 'CancelAnimationFrame'] || window[vendors[x] + 'CancelRequestAnimationFrame'];
		}

		if (!window.requestAnimationFrame) {
			window.requestAnimationFrame = function (callback, element) {
				var currTime = new Date().getTime();
				var timeToCall = Math.max(0, 16 - (currTime - lastTime));
				var id = window.setTimeout(function () {
						callback(currTime + timeToCall);
					},
					timeToCall);
				lastTime = currTime + timeToCall;
				return id;
			};
		}

		if (!window.cancelAnimationFrame) {
			window.cancelAnimationFrame = function (id) {
				clearTimeout(id);
			};
		}
	})();

};

/**
 * THIS FUNCTION IS SETTING THE HEIGHT OF THE SLIDESHOW ON CONTENT PAGES
 */

Customer.prototype.setSlideshowHeight = function () {
	var browserWidth = $(window).width();
	var itemHeight = (550 / 1920) * browserWidth;
	$("#slideshow").each(function () {
		$(this).height(Math.round(itemHeight));
	});
	$("#slideshow .item").each(function () {
		$(this).height(Math.round(itemHeight));
	});
};

/**
 * THIS FUNCTION IS SCROLLING TO THE POSITION
 * OF A ONEPAGER CONTENT ELEMENT
 * @param urlParam
 * @return {boolean}
 */

Customer.prototype.scrollToPosition = function (urlParam) {
	if ($.browser.opera) {
		var target = 'html';
	} else {
		var target = 'html,body';
	}

	var topPos = 0;

	topPos = window.scrollMatrix[urlParam];

	$(target).on("scroll mousedown wheel DOMMouseScroll mousewheel keyup touchmove", function () {
		$(target).stop();
	});

	$(target).animate({
		scrollTop: topPos
	}, 2000, function () {
		location.hash = urlParam;
		$(target).off("scroll mousedown wheel DOMMouseScroll mousewheel keyup touchmove");
	});
	return false;
};

/**
 * THIS FUNCTION CHECK IF ELEMENT TO SCROLL IS EXISTING
 * @param url
 * @return {*}
 */

Customer.prototype.checkIfElementExists = function (url) {
	var id = url.split('#');


	if (id.length > 1) {

		var lowerUrl = id[1].toLowerCase();
		var resone = lowerUrl.replace(' ', '_');
		var restwo = resone.replace('ä', 'ae');
		var resthree = restwo.replace('ö', 'oe');
		var resfour = resthree.replace('ü', 'ue');
		var result = resfour.replace('ß', 'ss');

		if ($('section[data-id="' + result + '"]').length > 0) {
			return result;
		} else {
			return false;
		}
	} else {
		return false;
	}
};

/**
 * THIS FUNCTION IS CREATING A MATRIX WITH ALL SECTION ELEMENTS
 * AND THERE TOP POSITIONS (TOP POSITION MUST NOT CALCULATE ON CLICK)
 * !!!! PERFORMANCE FEATURE !!!!
 */

Customer.prototype.createMatrix = function () {
	var matrix = [];

	$('section.onepager-element').each(function () {
		var self = $(this);
		var selfId = self.attr('data-id');
		var selfTop = self.offset().top;
		matrix[selfId] = selfTop;
	});

	window.scrollMatrix = matrix;
};

Customer.prototype.getUrlParameter = function (sParam) {
	'use strict';
	var sPageURL = window.location.search.substring(1);
	var sURLVariables = sPageURL.split('&');
	for (var i = 0; i < sURLVariables.length; i++) {
		var sParameterName = sURLVariables[i].split('=');
		if (sParameterName[0] == sParam) {
			return sParameterName[1];
		}
	}
};

/**
 * IF DOCUMENT IS READY LOADED BUT NOT IF IMAGES OR SOMETHING ELSE IS LOADED
 */

$(document).ready(function () {
	'use strict';

	// CREATE NEW CUSTOMER FUNCTION OBJECT (SETTING INTO GLOBAL VARIABLE)
	window.customer = new Customer();

	// SET HEIGHT OF THE SLIDESHOW IN THE HEAD OF THE PAGE
	window.customer.setSlideshowHeight();

	// CREATE THE SLIDER POSITION MATRIX (ARRAY)
	window.customer.createMatrix();

	// ON OPENING A SUB-NAVIGATION CLOSE OTHER SUB-NAVIGATION IF IS OPEN
	$('.nav-wrapper').on('show.bs.collapse', function () {
		var currentId = $(this).attr('id');
		$('.nav-wrapper').each(function () {
			if ($(this).attr('id') !== currentId) {
				$(this).collapse('hide');
			}
		});
	});

	$('#to-top-button').on('click touchend', function(e){
		e.preventDefault();
		TweenMax.to(window, 1.5, {scrollTo: {y: 0}});
	});

	window.addEventListener('scroll', function(e) {
		var distanceY = window.pageYOffset || document.documentElement.scrollTop,
				shrinkOn = $(window).height(),
				headerTop = 60,
				toTopButton = $("#to-top-button");
		if (distanceY > shrinkOn) {
			toTopButton.addClass("show");
		} else {
			if (toTopButton.hasClass("show")) {
				toTopButton.removeClass("show");
			}
		}
		if (distanceY > headerTop) {
			$('html').addClass("small-header");
		} else {
			if ($('html').hasClass("small-header")) {
				$('html').removeClass("small-header");
			}
		}
	});

	//console.log('test');
	//
	//function setHeader(xhr) {
	//	xhr.setRequestHeader('Authorization', token);
	//}
	// $.ajax({
	// 		type:'post',
	// 		url: "/ajax_proxy.php",
	// 		data:{
	// 			path:"xmlexport/xml_dyn.aspx?user=1710101328&pass=633256&art=10&auf=1710101328"
	// 		},
	// 		//async: true,
	// 		crossDomain: false,
	// 		dataType: "xml",
	// 	success: function (data) {
	// 		var $xml = $(data);
	// 		var $lastGames = $xml.find('LetzteSpiele');
	//
	//
	// 		var $lastGame = $lastGames.find('Spiel').first();
	//
	// 			if($lastGame.length > 0) {
	//
	//
	// 			var nrHome = $lastGame.find('HeimNr')[0].textContent;
	// 			var nrGuest = $lastGame.find('GastNr')[0].textContent;
	//
	// 			var nameHome = $lastGame.find('Heim')[0].textContent;
	// 			var nameGuest = $lastGame.find('Gast')[0].textContent;
	//
	// 			var goalHome = $lastGame.find('Tore1')[0].textContent;
	// 			var goalGuest = $lastGame.find('Tore2')[0].textContent;
	//
	// 			$.get('fileadmin/user_upload/team_logos/team-logo_'+nrHome+'.png')
	// 					.done(function(){
	// 						$('.home-logo.last-game').each(function(){
	// 							$(this)[0].src = 'fileadmin/user_upload/team_logos/team-logo_'+nrHome+'.png';
	// 						});
	// 					});
	//
	// 			$.get('fileadmin/user_upload/team_logos/team-logo_'+nrGuest+'.png')
	// 					.done(function(){
	// 						$('.guest-logo.last-game').each(function(){
	// 							$(this)[0].src = 'fileadmin/user_upload/team_logos/team-logo_'+nrGuest+'.png';
	// 						});
	// 					});
	// 			//console.log(HomeImage);
	//
	// 			$('.home-name.last-game').each(function(){
	// 				$(this)[0].innerHTML = nameHome;
	// 			});
	// 			$('.guest-name.last-game').each(function(){
	// 				$(this)[0].innerHTML = nameGuest;
	// 			});
	//
	// 			$('.home-goals.last-game').each(function(){
	// 				$(this)[0].innerHTML = goalHome;
	// 			});
	// 			$('.guest-goals.last-game').each(function(){
	// 				$(this)[0].innerHTML = goalGuest;
	// 			});
	//
	// 		}
	//
	//
	// 	}
	// });



	$('body').on('focus','.tx-powermail.business .layout1 input',function(){
		var selfInput = $(this);
		var selfForm = selfInput.parents('.tx-powermail.business');
		if(!selfForm.hasClass('open')) {
			selfForm.find('.col-sm-6').addClass('col-sm-11').removeClass('col-sm-6');
			selfForm.find('.col-sm-3').addClass('col-sm-1').removeClass('col-sm-3').find('.pull-right').removeClass('pull-right');
			selfForm.addClass('open');
		}
	});

	// FRONTPAGE AGENTCY PARTNER LINKS BOX OPENING
	if($('.agency-partners').length > 0) {
		$('body').on('click','.agency-partners',function(){
			var apartner = $(this);
			if(!apartner.hasClass('open')) {
				apartner.addClass('open');
			} else {
				apartner.removeClass('open');
			}
		});
		$('body').on('click','.agency-partners a',function(e){
			e.stopPropagation();
		});
	}

	/**
	 * INIT EVENTS PAGER
	 */
	if ($('.event-list').length > 0) {
		window.events = new Events;
	}

	/**
	 * INIT NEWS PAGER
	 */
	if ($('.news-list').length > 0) {
		window.news = new News;
	}

	// OPEN SEARCH FIELD BOX IF GET PARAMETER IS SET
	var searchWord = customer.getUrlParameter('tx_kesearch_pi1%5Bsword%5D');

	if (typeof searchWord === 'undefined') {
		searchWord = customer.getUrlParameter('tx_kesearch_pi1[sword]');
	}

	if (searchWord) {
		$('#ke_search_sword').val(searchWord);
	}

	$('header .row.equal-height .title.smartphone').on('click touchend', function (e) {
		e.preventDefault();
		if($(window).width() < 768) {
			if($(this).hasClass('label-nextgame')) {
				if($(this).hasClass('small')) {
					$('.label-nextgame').removeClass('small');
					$('.label-shorttable').addClass('small');
					TweenMax.to('#shorttable-header', 0.4, {scale:0.025,x:'-50%', force3D:true,opacity: 0, ease:Linear.easeNone}).eventCallback("onComplete", function () {
						TweenMax.to('#nextgame-header', 0.4, {scale:1,x:'-50%', force3D:true,opacity: 1, ease:Linear.easeNone});
					});

				}
			} else {
				if($(this).hasClass('small')) {
					$('.label-nextgame').addClass('small');
					$('.label-shorttable').removeClass('small');
					TweenMax.to('#nextgame-header', 0.4, {scale:0.025,x:'-50%', force3D:true,opacity: 0, ease:Linear.easeNone}).eventCallback("onComplete", function () {
						TweenMax.to('#shorttable-header', 0.4, {scale:1,x:'-50%', force3D:true,opacity: 1, ease:Linear.easeNone});
					});
				}
			}
		}
	});

	// Stops scrolling animation on user action (scrolling, clicking or key press)
	$('body,html').on('scroll mousedown wheel DOMMouseScroll mousewheel keyup', function(e) {
		if ( e.which > 0 || e.type === 'mousedown' || e.type === 'mousewheel') {
			$('html,body').stop();
		}
	});
});

function getParameterByName(name, url) {
	if (!url) url = window.location.href;
	name = name.replace(/[\[\]]/g, "\\$&");
	var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
			results = regex.exec(decodeURIComponent(url));
	if (!results) return null;
	if (!results[2]) return '';
	return decodeURIComponent(results[2].replace(/\+/g, " "));
}

/**
 * FUNCTION IF PAGE IS FULLY LOADED INCLUDING IMAGES AND THESE THINGS
 */

$(window).load(function () {

	// ON CLICK TO A SUB-NAVIGATION LINK CALL THE SCROLL TO
	// POSITION FUNCTION AND CHANGE HASH IN URL
	// $('body').on("click", '.nav-wrapper ul li a, #mobile-nav li ul li a', function (e) {
	// 	e.preventDefault();
	// 	var url = $(this).attr('href');
	//
	// 	var result = false;
	// 	result = window.customer.checkIfElementExists(url);
	//
	// 	if (result != false) {
	// 		$(this).parents('div.nav-wrapper').collapse('hide');
	// 		window.customer.scrollToPosition(result);
	// 	} else {
	// 		location.href = url;
	// 	}
	// });

	// IF HASH IS EXISTING IN URL ON PAGE LOAD SCROLL TO TOP POSITION
	// BUT ONLY IF ELEMENT TO SCROLL IS EXISTING
	// if (location.hash) {
	// 	var url = location.href;
	// 	var result = window.customer.checkIfElementExists(url);
	// 	if (result != false) {
	// 		window.customer.scrollToPosition(result);
	// 	}
	// }
	if(getParameterByName('tx_powermail_pi1[controller]')) {
		var $powermailPos = $('.tx-powermail').parents('section').offset().top;
		var headerHeight = $('#meta-header').height() + $('#main-header').height();
		if($(window).width() < 768) {
			headerHeight =  $('#main-header').height() + 12;
		}
		TweenMax.to(window, 1, {scrollTo: {y: $powermailPos - headerHeight}}).eventCallback("onComplete", function () {

		});
	}

	if(window.location.hash) {
		var hash = window.location.hash;
		var newsItem = hash.split('news-item-')[1];
		if(!newsItem) {
			TweenMax.to('#preloader',1, { ease: Power0.easeNone, display:'none', opacity: 0} );
		}
	} else {
		TweenMax.to('#preloader',1, { ease: Power0.easeNone, display:'none', opacity: 0} );
	}

});

/**
 * ON RESIZE THE BROWSER SET NEW SLIDESHOW HEIGHT
 * AND UPDATE THE SCROLL POSITION MATRIX
 */
$(window).resize(function () {
	window.customer.setSlideshowHeight();
	window.customer.createMatrix();
});
//
// var everythingLoaded = setInterval(function() {
// 	if (/loaded|complete/.test(document.readyState)) {
// 		clearInterval(everythingLoaded);
// 		if(window.location.hash) {
// 			var hash = window.location.hash;
// 			var newsItem = hash.split('news-item-')[1];
// 			if(!newsItem) {
// 				TweenMax.to('#preloader',1, { ease: Power0.easeNone, display:'none', opacity: 0} );
// 			}
// 		} else {
// 			TweenMax.to('#preloader',1, { ease: Power0.easeNone, display:'none', opacity: 0} );
// 		}
//
// 	}
// }, 10);


